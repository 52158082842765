import { useEffect } from 'react';  // Import useEffect only
import axios from 'axios';  // Import axios

const IPLogger = () => {
  useEffect(() => {
    const logIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const clientIP = response.data.ip;

        const webhookUrl = process.env.REACT_APP_CF_WORKER_URL;
        await axios.post(webhookUrl, { ip: clientIP }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.error('Error logging IP:', error);
      }
    };

    logIP();
  }, []);

  return null;
};

export default IPLogger;
